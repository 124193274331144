import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { Lightbox } from "../components/images/lightbox-gallery";

// Icons
import { Next, Prev } from "../components/icons/arrows";

const Page = styled.div`
  margin: 0 0 130px 0;

  @media (max-width: 768px) {
    margin: 0 0 95px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  & .left-column {
    grid-column: 1 / 7;

    & .artwork-image-container {
      cursor: pointer;

      margin: 0 0 140px 0;

      &:last-of-type {
        margin: 0;
      }
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  & .right-column {
    grid-column: 8 / 13;

    @media (max-width: 970px) {
      grid-column: 7 / 13;
    }

    & .outer-container {
      position: relative;
      height: 100%;

      & .sticky {
        position: sticky;
        top: 100px;

        height: calc(100vh - 120px - 85px);

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;

        & .text-container {
          max-width: 440px;

          & .title {
            margin: 0 0 30px 0;

            @media (max-width: 768px) {
              display: none;
            }
          }

          & .details {
            & p {
              &:first-of-type {
                margin-top: 0;
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          & .dimensions {
            margin: 0 0 30px 0;

            & p {
              &:first-of-type {
                margin-top: 0;
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }

          & .credits {
            & p {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.05em;

              margin: 0;
            }
          }

          & .mobile-navigation-container {
            display: none;
          }

          @media (max-width: 768px) {
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;

            & .mobile-navigation-container {
              display: block;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .left-column {
      grid-column: unset;

      & .artwork-image-container {
        margin: 0 0 40px 0;

        &:last-of-type {
          margin: 0 0 40px 0;
        }
      }
    }

    & .right-column {
      grid-column: unset;
      margin: 0 0 100px 0;

      & .outer-container {
        & .sticky {
          position: relative;
          top: unset;

          height: auto;

          & .text-container {
            // max-width: 80%;
            width: 100%;
            margin: 0 auto;

            text-align: center;

            & .details-container {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;

              margin: 0 0 20px 0;

              & .details-wrapper {
                max-width: 220px;

                & .details,
                & .dimensions {
                  margin: 0;

                  & p {
                    font-size: 16px;
                    line-height: 24px;

                    & a {
                      // text-decoration: underline;
                      // text-underline-offset: 2px;
                    }
                  }
                }
              }
            }

            & .credits {
              max-width: 220px;
              margin: 0 auto;

              & p {
                font-size: 12px;
                line-height: 18px;

                & a {
                  // text-decoration: underline;
                  // text-underline-offset: 2px;
                }
              }
            }
          }
        }
      }
    }
  }

  & .mobile-title {
    display: none;
    text-align: center;
    margin: 0 0 30px 0;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  margin: 60px 0 0 0;

  & svg {
    &:first-of-type {
      margin: 0 40px 0 0;
    }
  }

  & a {
    &:hover {
      color: #163262;

      & svg {
        & path {
          transition: 250ms stroke ease, 250ms fill ease;

          &:first-of-type {
            stroke: #163262;
          }

          &:last-of-type {
            fill: #163262;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  transition: 250ms color ease;
`;

const Artwork = ({ data, location }) => {
  // Tags and filters
  const [activeTag, setActiveTag] = useState(`all`);
  const [activeView, setActiveView] = useState(`gallery`);

  // Links
  const homepageProjects = data.prismicHomepage.data.artworks
    .filter(artwork => artwork.artwork.document !== null)
    .map(artwork => artwork.artwork.document.url);

  const currentArtworkLinkPosition = homepageProjects.indexOf(
    data.prismicArtwork.url
  );

  const nextArtwork =
    currentArtworkLinkPosition < homepageProjects.length - 1
      ? homepageProjects[currentArtworkLinkPosition + 1]
      : homepageProjects[0];

  const [nextLink, setNextLink] = useState(nextArtwork);
  const [prevLink, setPrevLink] = useState(`/`);

  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [activeSlideNumber, setActiveSlideNumber] = useState(0);

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (
          location.state.activeTag !== undefined &&
          location.state.activeTag !== null
        ) {
          setActiveTag(location.state.activeTag);
        }

        if (
          location.state.activeView !== undefined &&
          location.state.activeView !== null
        ) {
          setActiveView(location.state.activeView);
        }

        if (
          location.state.artworks !== undefined &&
          location.state.artworks !== null
        ) {
          const currentArtworkLinkPosition = location.state.artworks.indexOf(
            data.prismicArtwork.url
          );

          const nextArtwork =
            currentArtworkLinkPosition < location.state.artworks.length - 1
              ? location.state.artworks[currentArtworkLinkPosition + 1]
              : location.state.artworks[0];

          // const prevArtwork =
          //   currentArtworkLinkPosition === 0
          //     ? location.state.artworks[location.state.artworks.length - 1]
          //     : location.state.artworks[currentArtworkLinkPosition - 1];

          setNextLink(nextArtwork);
          // setPrevLink(prevArtwork);
        } else {
          setNextLink(nextArtwork);
        }
      } else {
        setNextLink(nextArtwork);
      }
    } else {
      setNextLink(nextArtwork);
    }
  }, [location]);

  const images = data.prismicArtwork.data.images
    .filter(content => content.image.fluid !== null)
    .map((content, index) => (
      <div
        className="artwork-image-container"
        key={`single_artwork_image_${index}`}
        onClick={() => {
          setIsLightboxOpen(true);
          setActiveSlideNumber(index);
        }}
      >
        <img
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={
            index <= 2 || index === data.prismicArtwork.data.images.length - 1
              ? `eager`
              : `lazy`
          }
        />
      </div>
    ));

  const seoImage =
    data.prismicArtwork.data.images.length >= 1 &&
    data.prismicArtwork.data.images[0].image.fluid !== null
      ? data.prismicArtwork.data.images[0].image.url
      : null;

  return (
    <>
      <PageSEO
        title={data.prismicArtwork.data.title.text}
        description={data.prismicArtwork.data.details.text}
        image={seoImage}
        url={`https://yinkashonibare.com${data.prismicArtwork.url}`}
      />
      <Page>
        <Grid>
          <div className="mobile-title">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: data.prismicArtwork.data.title.html,
              }}
            />
          </div>

          <div className="left-column">{images}</div>

          <div className="right-column">
            <div className="outer-container">
              <div className="sticky">
                <div className="text-container">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: data.prismicArtwork.data.title.html,
                    }}
                  />

                  <div className="details-container">
                    <div className="mobile-navigation-container">
                      <Link
                        to={`/`}
                        state={{
                          activeTag: activeTag,
                          activeView: activeView,
                          artworkId: data.prismicArtwork.id,
                        }}
                      >
                        <Prev />
                      </Link>
                    </div>

                    <div className="details-wrapper">
                      <div
                        className="details"
                        dangerouslySetInnerHTML={{
                          __html: data.prismicArtwork.data.details.html,
                        }}
                      />
                      <div
                        className="dimensions"
                        dangerouslySetInnerHTML={{
                          __html: data.prismicArtwork.data.dimensions.html,
                        }}
                      />
                    </div>

                    <div className="mobile-navigation-container">
                      <Link
                        to={nextLink}
                        state={{
                          activeTag: activeTag,
                          activeView: activeView,
                          artworks:
                            location !== undefined &&
                            location !== null &&
                            location.state !== undefined &&
                            location.state !== null &&
                            location.state.artworks !== undefined &&
                            location.state.artworks !== null
                              ? location.state.artworks
                              : null,
                        }}
                      >
                        <Next />
                      </Link>
                    </div>
                  </div>

                  <div
                    className="credits"
                    dangerouslySetInnerHTML={{
                      __html: data.prismicArtwork.data.credits.html,
                    }}
                  />

                  <Navigation>
                    <Link
                      to={`/`}
                      state={{
                        activeTag: activeTag,
                        activeView: activeView,
                        artworkId: data.prismicArtwork.id,
                      }}
                    >
                      <Prev />
                    </Link>
                    <Link
                      to={nextLink}
                      state={{
                        activeTag: activeTag,
                        activeView: activeView,
                        artworks:
                          location !== undefined &&
                          location !== null &&
                          location.state !== undefined &&
                          location.state !== null &&
                          location.state.artworks !== undefined &&
                          location.state.artworks !== null
                            ? location.state.artworks
                            : null,
                      }}
                    >
                      <Next />
                    </Link>
                  </Navigation>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Page>

      {isLightboxOpen && (
        <Lightbox
          isLightboxOpen={isLightboxOpen}
          setIsLightboxOpen={setIsLightboxOpen}
          images={data.prismicArtwork.data.images}
          activeSlideNumber={activeSlideNumber}
        />
      )}
    </>
  );
};

export default withPreview(Artwork);

export const query = graphql`
  query Artwork($uid: String!) {
    prismicArtwork(uid: { eq: $uid }) {
      url
      id
      data {
        title {
          html
          text
        }
        details {
          html
          text
        }
        dimensions {
          html
        }
        credits {
          html
        }
        images {
          image {
            alt
            url
            fluid {
              srcWebp
              srcSetWebp
            }
            dimensions {
              height
              width
            }
          }
        }
      }
    }
    prismicHomepage {
      data {
        artworks {
          artwork {
            document {
              ... on PrismicArtwork {
                url
              }
            }
          }
        }
      }
    }
  }
`;
